export const CUSTOMER_EMAIL = '/customers/settings/email';
export const CUSTOMER_PASSWORD = '/customers/settings/password';
export const CUSTOMER_PROFILE = '/customers/settings/profile-info';
export const CUSTOMER_BOOKINGS = '/customers/bookings';
export const INSTAGRAM_HREF = 'https://www.instagram.com/koralgo_travel/';

export const SELLER_NAVBAR = [
  { title: 'My Properties', href: '/sellers/my-properties' },
  { title: 'Payments received', href: '/sellers/payments' },
  { title: 'Payments owed', href: '/sellers/owed' },
  { title: 'Admin Settings', href: '/sellers/admin-password' },
];

export const CUSTOMER_NAVBAR = [
  { title: 'Settings', href: '/customers/settings/email' },
  { title: 'Preferences', href: '/customers/search-profile' },
  //  { title: 'Bookings', href: '/customers/bookings' },
];

export const PROPERTY_CARD_LINKS = [
  { title: 'Property Details', href: '/sellers/my-properties/:id' },
  { title: 'Rooms', href: '/sellers/my-properties/:id/rooms' },
  { title: 'Offers', href: '/sellers/my-properties/:id/offers' },
  // { title: 'Marketing', href: '/sellers/my-properties/:id/marketing' },
  // { title: 'Analytics', href: '/sellers/my-properties/:id/analytics' },
  { title: 'Bookings', href: '/sellers/my-properties/:id/bookings' },
  { title: 'Review', href: '/sellers/my-properties/:id/review' },
];

export const UNAUTH_NAV_DATA = [
  // { title: 'About', href: '/about/' },
  { title: 'Info', href: '/faq/' },
  // { title: 'Ratings', href: '/koreval/' },
  // { title: 'Contact', href: '/contact/' },
  // { title: 'Seller', href: '/koralgo-for-seller/' },
];

export const PARTNER_NAV_DATA = [
  { title: 'Info', href: '/faq/' },
  { title: 'Koreval', href: '/koreval/' },
];

export const SIGN_LINKS = [{ title: 'Log In', href: '/signin' }];

export const USER_INFO_PAGES = [
  { title: 'Privacy Policy', href: '/privacy-policy' },
  { title: 'Cookies Policy', href: '/cookies-policy' },
  { title: 'Terms of Service', href: '/terms-of-service' },
  { title: 'Contact', href: '/contact/' },
  { title: 'Sitemap', href: '/locations' },
];

export const FOOTER_LINKS = {
  instagram: { title: 'Follow us', href: INSTAGRAM_HREF },
};

export const PROPERTY_EDIT_NAV = [
  {
    title: 'General Info',
    href: '/sellers/my-properties/:id/edit/general-info',
  },
  {
    title: 'Property photos',
    href: '/sellers/my-properties/:id/edit/property-photos',
  },
  {
    title: 'Location and transports',
    href: '/sellers/my-properties/:id/edit/location-transports',
  },
  { title: 'Facilities', href: '/sellers/my-properties/:id/edit/facilities' },
  {
    title: 'Food & Beverages',
    href: '/sellers/my-properties/:id/edit/food-beverages',
  },
  { title: 'Rooms', href: '/sellers/my-properties/:id/edit/rooms' },
  {
    title: 'Terms and Conditions',
    href: '/sellers/my-properties/:id/edit/terms-conditions',
  },
];

export const ROOM_EDIT_NAV = [
  {
    title: 'General Info',
    href: '/sellers/my-properties/:id/rooms/:roomId/edit/general-info',
  },
  {
    title: 'Room photos',
    href: '/sellers/my-properties/:id/rooms/:roomId/edit/room-photos',
  },
  {
    title: 'Bed settings',
    href: '/sellers/my-properties/:id/rooms/:roomId/edit/bed-settings',
  },
  {
    title: 'Room amenities',
    href: '/sellers/my-properties/:id/rooms/:roomId/edit/room-amenities',
  },
];

export const SELLER_SETTINGS_NAV = [
  {
    title: 'Password',
    href: '/sellers/admin-password',
  },
  {
    title: 'Payment Account',
    href: '/sellers/admin-payment/return',
  },
];

export const CUSTOMER_SETTINGS_HOME_NAV = [
  {
    title: 'Email settings',
    href: CUSTOMER_EMAIL,
  },
  {
    title: 'Password settings',
    href: CUSTOMER_PASSWORD,
  },
  {
    title: 'Edit profile option',
    href: CUSTOMER_PROFILE,
  },
];

export const CUSTOMER_SETTINGS_NAV = [
  {
    title: 'Change email',
    href: CUSTOMER_EMAIL,
  },
  {
    title: 'Change password',
    href: CUSTOMER_PASSWORD,
  },
  {
    title: 'Profile info',
    href: CUSTOMER_PROFILE,
  },
];

export const MY_PROPERTIES_LINK = '/sellers/my-properties';
export const CONTACTS_LINK = '/contact/';
export const SEARCH_LINK = '/search';
export const START_LINK = '/';
export const OLD_SEARCH_LINK = '/old-search';
export const BLOCKED_USER_LINK = '/user-blocked/';
export const VERIFICATION_LINK = '/verification/';
export const EXPRESS_INTEREST_LINK = '/express-interest/';
export const KORALGO_FOR_SELLER_LINK = '/koralgo-for-seller/';
export const KOREVAL_LINK = '/koreval/';
export const SHARED_RESULTS_PATH = '/results-shared/';
export const NOT_FOUND = '/nothing-found/';
export const SIGN_IN = '/signin';
