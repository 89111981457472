import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, navigate } from 'gatsby';
import { Input } from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import { AUTH } from '../../constans/form-fields';
import { EMAIL_INVALID, EMAIL_REQUIRED } from '../../constans/error-messages';
import { EMAIL_MAX } from '../../constans/validation';
import { useDisplayServerError } from '../../hooks/useDisplayServerError';
import http from '../../services/http.service';
import { API, HTTP_STATUS } from '../../constans/http';
import classes from './index.module.scss';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import Label from '../../components/UI/Label/Label';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import { BLOCKED_USER_LINK, VERIFICATION_LINK } from '../../constans/links';
import StorageService from '../../services/storage.service';

const initialValues = {
  email: AUTH.email.initialValue,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required(EMAIL_REQUIRED)
    .email(EMAIL_INVALID)
    .max(EMAIL_MAX),
});

const Index = () => {
  const [serverError, setServerError] = useDisplayServerError();

  const formSubmitHandler = ({ email }) => {
    http
      .post(API.PASSWORD_RESET, { email })
      .then(() => {
        // Navigate to success page
        // set restoreEmail to LS
        StorageService().set(LOCALSTORAGE_KEYS.RESET_PWD_EMAIL, email);
        navigate('/password-notif/');
      })
      .catch(({ status, message }) => {
        if ([HTTP_STATUS.BLOCKED_USER].includes(status)) {
          navigate(BLOCKED_USER_LINK);
        } else if ([HTTP_STATUS.UNVERIFIED].includes(status)) {
          navigate(VERIFICATION_LINK, { state: { pwdRedirect: true } });
        } else {
          setServerError(message);
        }
      });
  };

  return (
    <div className="blue-grad-bg center-vertical-page-wrapper center-content-vertical-mobile">
      <div className={`${classes.container} container-bordered auth-wrapper`}>
        <div className="auth-title block-title">
          Reset Password
          {serverError && <div className="titleError">{serverError}</div>}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={formSubmitHandler}
        >
          <Form className="flex flex-col flex-v-end">
            <InputWrapper containerClasses={classes.inputWrapper}>
              <Label>Email</Label>
              <Input
                type="email"
                name={AUTH.email.name}
                placeholder={AUTH.email.placeHolder}
              />
            </InputWrapper>
            <Button
              classes=" btn btn_block btn-uppercased btn_common t-600"
              type="submit"
            >
              Reset password
            </Button>
          </Form>
        </Formik>
        <div className={classes.footer}>
          <Link to="/signin/" className={`${classes.loginLink} t-600`}>
            Back to Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Index;
